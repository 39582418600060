import { ReactComponent as ProLabelHorizontal } from 'app-images/icons/emoji/pro-label-horizontal.svg'
import { ReactComponent as ProLabelVertical } from 'app-images/icons/emoji/pro-label-vertical.svg'
import { FC } from 'react'

interface LabelProProps {
  label: 'vertical' | 'horizontal'
  tooltip: string
  styles?: React.CSSProperties
}
const LabelPro: FC<LabelProProps> = (props) => {
  const styles: React.CSSProperties = { ...props.styles }
  if (props.label === 'vertical') {
    return (
      <ProLabelVertical
        style={styles}
        className="label__pro"
        data-for="app-tooltip"
        data-tip={props.tooltip}
        data-class={'label-pro'}
      />
    )
  }
  return (
    <ProLabelHorizontal
      style={styles}
      className="label__pro"
      data-for="app-tooltip"
      data-tip={props.tooltip}
      data-class={'label-pro'}
    />
  )
}

export default LabelPro
